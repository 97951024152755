import { Box, ChakraProps, colors, Flex, Grid, Heading, Link, Stack, Text } from "@biblioteksentralen/js-utils";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { DecorativeImage, SanityBlock, SanityKeyed, SplashPage, SplashPageSection } from "@libry-content/types";
import { PublicationImage } from "../lists/PublicationImage";
import SanityImage from "../SanityImage";
import SplashPageBlockContent from "./SplashPageBlockContent";
import SplashPageSectionWrapper from "./SplashPageSectionWrapper";
import { imageUrlBuilder } from "../../utils/sanity/client";
import BlockContent from "../blockContent/BlockContent";
import { safelyGetImageDimensions } from "../../utils/safelyGetImageDimensions";

const LogoImage = styled.img`
  height: 2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`;

export type SplashPageProps = {
  splashPage?: SplashPage;
};

function SplashPage({ splashPage }: SplashPageProps) {
  return (
    <Box position="relative">
      <Global
        styles={css`
          html {
            font-size: 140%;
          }
        `}
      />
      {splashPage?.logoBW?.asset && (
        <LogoImage src={imageUrlBuilder(splashPage?.logoBW)?.height(75).url() ?? ""} alt="" />
      )}
      {splashPage?.sections?.map((section) => (
        <SplashPageSectionWrapper aria-labelledby={section._key} key={section._key} section={section}>
          <Layout key={section._key} {...section} />
        </SplashPageSectionWrapper>
      ))}
      <Box as="footer" background={colors.grey90} color={colors.white} padding="1rem" fontSize="sm">
        <Link href="https://www.bibsent.no">Biblioteksentralen</Link>
      </Box>
    </Box>
  );
}

const Layout = (props: SanityKeyed<SplashPageSection>) => {
  switch (props?.layout) {
    case "header":
      return (
        <Stack height="100%" paddingTop="5vh" textAlign={{ md: "center" }}>
          <Heading as="h1" fontSize={{ base: "5xl", sm: "7xl" }} fontFamily="serif" lineHeight={1}>
            {props?.title}
          </Heading>
          <Text>{props?.pretitle}</Text>
          <SplashPageBlockContent textAlign="center" blocks={props.body} marginTop="4em !important" />
        </Stack>
      );
    case "imageRight":
      return (
        <Flex gap="2em 4em" direction={{ base: "column", md: "row" }} flexGrow={1}>
          <Stack spacing="2rem" flex="1 1 30%">
            <Header section={props} />
            <SplashPageBlockContent blocks={props.body} />
          </Stack>
          <Box position="relative" flex="1 1 40%" paddingTop="4vmin">
            {props.media?.map((media, i) => (
              <Box
                key={media._key}
                position={i > 0 ? "absolute" : undefined}
                top={i * 15 + "vmin"}
                left={i * 12 + "vmin"}
                paddingBottom="15vmin"
                paddingRight="12vmin"
                width="100%"
              >
                <Media media={media} boxShadow="0 .1rem .3rem rgba(0,0,0,.2)" />
              </Box>
            ))}
          </Box>
        </Flex>
      );
    case "contentLeftImageUnderTitle":
      return (
        <Flex gap="4em" direction={{ base: "column", md: "row-reverse" }} flexGrow={1}>
          <Stack spacing="2em" position="relative">
            <Header section={props} />
            <Media media={props.media?.[0]} />
          </Stack>
          <SplashPageBlockContent paddingTop="4vmin" blocks={props.body} />
        </Flex>
      );
    case "linear":
      return (
        <Stack gap="3em" flexGrow={1}>
          <Header section={props} />
          <SplashPageBlockContent alignSelf="center" blocks={props.body} maxW="45em !important" textAlign="center" />
          <Grid
            padding="2rem"
            templateColumns="repeat(auto-fit, minmax(10rem, 1fr))"
            gridGap="3em"
            width="100%"
            justifyContent="center"
          >
            {props.media?.map((media, i) => (
              <Media key={media._key} media={media} />
            ))}
          </Grid>
        </Stack>
      );
    case "contentRightImageUnderTitle":
      return (
        <Flex gap="4em" direction={{ base: "column", md: "row" }} flexGrow={1}>
          <Stack spacing="2em" position="relative">
            <Header section={props} />
            <Media media={props.media?.[0]} />
          </Stack>
          <SplashPageBlockContent blocks={props.body} />
        </Flex>
      );
    default:
      return null;
  }
};

const Header = ({ section, ...chakraProps }: { section: SanityKeyed<SplashPageSection> } & ChakraProps) => (
  <Stack
    spacing="0"
    as="h2"
    id={section._key}
    textAlign={section?.centerHeader ? "center" : undefined}
    {...chakraProps}
  >
    {section?.pretitle && (
      <Heading as="div" size="md" fontWeight="400">
        {section?.pretitle}
      </Heading>
    )}
    <Heading as="div" size="xl">
      {section?.title}
    </Heading>
  </Stack>
);

const QuoteName = styled.span`
  margin-top: 0.75em !important;

  p {
    font-size: var(--chakra-fontSizes-xs);
    line-height: 1.5;
    margin-bottom: 0 !important;
  }
`;

const prependBlockText = (blocks: SanityKeyed<SanityBlock>[], text: string): SanityKeyed<SanityBlock>[] =>
  blocks.map((block, currentBlockIndex) =>
    currentBlockIndex !== 0
      ? block
      : {
          ...block,
          children: block.children?.map((child: { text: string }, currentChildIndex: number) =>
            currentChildIndex !== 0 ? child : { ...child, text: `${text}${block.children[0].text}` }
          ),
        }
  );

const getDecorativeImageResolution = (image: DecorativeImage, multiplier = 600): number =>
  Math.round(multiplier * safelyGetImageDimensions(image).aspectRatio);

const Media = ({
  media,
  ...chakraProps
}: { media: SafeIndexedAccess<SplashPageSection["media"], number> } & ChakraProps) => {
  if (!media) return null;
  switch (media._type) {
    case "decorativeImage":
      if (!media.asset) return null;
      return (
        <>
          <Box display={{ base: "block", sm: "none" }}>
            <PublicationImage
              boxShadow="none"
              resolution={getDecorativeImageResolution(media, 300)}
              image={media}
              {...chakraProps}
            />
          </Box>
          <Box display={{ base: "none", sm: "block" }}>
            <PublicationImage
              boxShadow="none"
              resolution={getDecorativeImageResolution(media)}
              image={media}
              {...chakraProps}
            />
          </Box>
        </>
      );
    case "splashPageImageCard":
      return (
        <Flex flexDirection="column" textAlign="center">
          <Heading fontSize="1.1rem" as="h3" padding=".35em 1em">
            {media.title}
          </Heading>
          <Box boxShadow="0 .1rem .3rem rgba(0,0,0,.2)" borderRadius="xl" overflow="hidden">
            <SanityImage src={media.image} height={250} width={400} borderRadius="0" />
            <Text flexGrow={1} fontSize="xs" padding=".35em 1em" minH="3rem" backgroundColor={colors.lightBlue}>
              {media.ingress}
            </Text>
          </Box>
        </Flex>
      );
    case "splashPageTestimony":
      return (
        <Stack spacing="1em" textAlign="center">
          <Box paddingX=".5em">
            <SanityImage src={media.image} height={400} width={400} borderRadius="3xl" />
          </Box>
          <Text fontSize="xs" as="blockquote">
            «{media.quote}»
          </Text>
          {/* TODO: Can remove this type check once data is migrated */}
          {!!media.name?.length && Array.isArray(media.name) && (
            <QuoteName>
              <BlockContent blocks={prependBlockText(media.name, "- ")} />
            </QuoteName>
          )}
        </Stack>
      );
    default:
      return null;
  }
};

// https://github.com/ricokahler/sanity-codegen/discussions/196
export type SafeIndexedAccess<
  T extends { [key: string]: any } | undefined,
  K extends keyof NonNullable<T>
> = T extends undefined ? NonNullable<T>[K] | undefined : NonNullable<T>[K];

export default SplashPage;
