import { SplashPage as SplashPageType } from "@libry-content/types";
import groq from "groq";
import { GetStaticProps } from "next";
import React from "react";
import { usePreviewContext } from "../components/preview/previewContext";
import SEO from "../components/SEO";
import SplashPage, { SplashPageProps } from "../components/splashPage/SplashPage";
import { sanityClient, usePreviewSubscription } from "../utils/sanity/client";

const query = groq`*[_type == "splashPage"][0]`;

export const getStaticProps: GetStaticProps = async (context) => {
  const splashPage = await sanityClient.fetch<SplashPageType | null>(query);

  return {
    props: { splashPage },
    revalidate: 60,
  };
};

function PageWrapper(props: SplashPageProps) {
  const context = usePreviewContext();

  const subscribtion = usePreviewSubscription(query, {
    initialData: props.splashPage,
    enabled: context.isOn,
  });

  return (
    <>
      <SEO title="Libry Content" description="Nettsidebygger for bibliotekene i Norge" path="/" icon="/favicon.png" />
      <SplashPage splashPage={subscribtion?.data} />
    </>
  );
}

export default PageWrapper;
