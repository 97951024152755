import { Box, Container, Flex, FlexProps } from "@biblioteksentralen/js-utils";
import { SplashPageSection } from "@libry-content/types";
import { FunctionComponent } from "react";
import { isBright } from "../../utils/colors";
import { imageUrlBuilder } from "../../utils/sanity/client";

type Props = { section: SplashPageSection } & FlexProps;

const SplashPageSectionWrapper: FunctionComponent<Props> = ({ section, children, ...chakraProps }) => {
  const backgroundColor = section?.backgroundColor;
  const backgroundImage = section?.backgroundImage?.asset && section.backgroundImage;

  return (
    <Box
      as="section"
      minH={section?.fillWindow ? "110vh" : undefined}
      backgroundColor={backgroundColor}
      overflow="hidden"
      position="relative"
      isolation="isolate"
      color={(backgroundColor && !isBright(backgroundColor)) || backgroundImage ? "white" : undefined}
      background={
        backgroundImage
          ? `linear-gradient(rgba(0, 0, 0, 0.727),rgba(0, 0, 0, 0.6)), url("${getBackgroundImage(section)}")`
          : undefined
      }
      backgroundSize="cover"
      backgroundPosition="center" // FIXME: Background image that respects hotspot
      {...chakraProps}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        position="relative"
        padding="20vh 5vw"
        minH={section?.fillWindow ? "100vh" : undefined}
      >
        <Container maxW={`container.${section.width ?? "md"}`}>{children}</Container>
      </Flex>
    </Box>
  );
};

const getBackgroundImage = (section: SplashPageSection) =>
  imageUrlBuilder(section?.backgroundImage)?.width(1200).format("webp").quality(80).url() ?? "";

export default SplashPageSectionWrapper;
