import { Box, BoxProps } from "@biblioteksentralen/js-utils";
import { DecorativeImage } from "@libry-content/types";
import NextImage from "next/image";
import { safelyGetImageDimensions } from "../../utils/safelyGetImageDimensions";
import { imageUrlBuilder } from "../../utils/sanity/client";

type Props = { image?: DecorativeImage; resolution: number; alt?: string } & BoxProps;

export function PublicationImage({ image, resolution, boxShadow = "md", alt = "", ...chakraProps }: Props) {
  if (!image?.asset) return null;

  const src = imageUrlBuilder(image)?.width(resolution).format("webp").quality(80).url() ?? "";
  const blurImageUrl = imageUrlBuilder(image)?.width(10).format("webp").quality(70).url();
  const dimensions = safelyGetImageDimensions(image);

  return (
    <Box
      lineHeight={0}
      borderRadius="0.5em"
      boxShadow={boxShadow}
      position="relative"
      overflow="hidden"
      {...chakraProps}
    >
      <NextImage
        placeholder="blur"
        blurDataURL={blurImageUrl}
        src={src}
        alt={alt}
        layout="intrinsic"
        height={Math.round(resolution / dimensions.aspectRatio)}
        width={resolution}
      />
    </Box>
  );
}
