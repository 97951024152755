import { BsCheckCircle, Button } from "@biblioteksentralen/js-utils";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ChakraProps,
  Heading,
  Link,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { SplashPageFaq, SplashPageRichText } from "@libry-content/types";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";

const richTextStyle = (maxW?: string) => css`
  width: 100%;
  > {
    * {
      max-width: ${maxW ?? "30em"};
      margin-left: auto;
      margin-right: auto;
    }
    p {
      line-height: 1.6;
      margin-bottom: 1.75rem;
    }
  }
`;

const components: Partial<PortableTextReactComponents> = {
  types: {
    splashPageFaq: ({ value }: { value: SplashPageFaq }) => {
      return (
        <Accordion allowToggle>
          <AccordionItem border="none">
            <AccordionButton
              display="flex"
              gap="1rem"
              justifyContent="space-between"
              border="1px solid"
              borderColor="gray.300"
            >
              <Heading size="xs" as="h3" textAlign="left" lineHeight="1.25rem">
                {value.question}
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel paddingBottom={0}>
              <SplashPageBlockContent fontSize="xs" blocks={value.answer} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    },
  },
  block: {
    h3: ({ children }) => (
      <Heading as="h3" size="lg">
        {children}
      </Heading>
    ),
    ingress: ({ children }) => <Text fontSize="lg">{children}</Text>,
    normal: ({ children }) => <Text fontSize="sm">{children}</Text>,
  },
  list: {
    bullet: ({ children }) => (
      <UnorderedList marginInlineStart={0} spacing="1.25em">
        {children}
      </UnorderedList>
    ),
  },
  listItem: {
    bullet: ({ children }) => (
      <ListItem display="flex" gap="1em" alignItems="center">
        <ListIcon as={BsCheckCircle} />
        {children}
      </ListItem>
    ),
  },
  marks: {
    link: (props) =>
      props.value.knapp ? (
        <Button
          backgroundColor="#D3EADE"
          color="gray.900"
          borderRadius="2rem"
          padding=".5rem 1.5rem"
          as="a"
          fontSize="sm"
          href={props.value.href}
          _hover={{
            opacity: 0.9,
          }}
        >
          {props.children}
        </Button>
      ) : (
        <Link href={props.value.href}>{props.children}</Link>
      ),
  },
};

interface Props extends ChakraProps {
  blocks?: SplashPageRichText;
  maxW?: string;
}

function SplashPageBlockContent({ blocks, ...chakraProps }: Props) {
  return (
    <Box css={richTextStyle(chakraProps.maxW)} {...chakraProps}>
      <PortableText value={blocks || []} components={components} />
    </Box>
  );
}

export default SplashPageBlockContent;
