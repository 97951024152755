import { colors } from "@biblioteksentralen/js-utils";
import convert from "color-convert";
import { seededRandom } from "./random";

export function isBright(color: string) {
  const lightness = convert.hex.hsl(color)[2];
  return lightness > 50;
}

export const accentColors = [
  colors.accentBlueDark,
  colors.accentGreen,
  colors.accentPeach,
  colors.accentPurple,
  colors.lightGreen,
  colors.lightPeach,
  colors.accentBlueMedium,
];

export const getAccentColorFromSeed = (seed: string) => {
  const index = Math.floor(seededRandom(seed) * accentColors.length);
  return accentColors[index];
};
